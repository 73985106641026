<template>

<b-container>
 <b-alert v-if="appState!='loading'"
            :show="alertComputed.alertVisible"
            :dismissible="alert.dismissible"
            :variant="alert.variant"
            @dismissed="hideAlert"
            @dismiss-count-down="countDownChanged">
        <p class="preserve-lb">{{alert.alertText}}</p>
        <b-progress v-show="alertComputed.alertProgressVisible"
                  :variant="alert.variant"
                  :max="alert.dismissSecs"
                  :value="alert.dismissCountDown"
                  height="4px">
        </b-progress>
    </b-alert>


  <icon v-if="appState=='loading'" name="spinner" pulse scale="4"/> 
  <div v-else-if="appState=='error'">Fehler beim Lesen der Daten.</div> 
  <div v-else-if="appState=='ready'"> 
       <b-row>
    <b-col>
      <b-form>
        <b-form-group id="exampleInputGroup1"
                    label="Edit poll"
                    label-for="exampleInput1"
                    >
           <b-form-input id="exampleInput1"
                      type="text"
                      v-model="form.title"
                      required
                      >
        </b-form-input>
      </b-form-group>
      </b-form>
      <b-form inline>
              <label for="inputClubNr">Club NR ({{ poll.club_nr }})</label>
                <b-form-input id="exampleInput1"
                      type="text"
                      v-model="form.clubNr"
                      required
                      >
                </b-form-input>
              <label for="inputGroupId">Group ID ({{ poll.groupId }})</label>
                <b-form-input id="exampleInput2"
                      type="text"
                      v-model="form.groupId"
                      required
                      >
                </b-form-input>
                <label for="inputTeamNr">Team Nr ({{ poll.team_nr }})</label>
                <b-form-select left="@" class="mb-2 mr-sm-2 mb-sm-0" id="inputTeamNr"
                              :options="teamNrs"
                              required
                              v-model="form.teamNr">
                </b-form-select>
             
      </b-form>
      <form>
        <b-form-checkbox @click.native.stop :id="'checkbox-active'"
                     v-model="form.active">
      active
    </b-form-checkbox>
      </form>
        </b-col>
 
   
   </b-row>
    <b-row>
      <b-col>
        <b-list-group id="allplayerslist">
          <b-list-group-item  v-for="(player,index) in allplayers" :key="player._id">
            <b-form-checkbox
              @click.native.stop
              :id="'checkbox1' + player.user_id"
              :data-test-id="'chb-' + player.user_id"
              v-model="playerEditModel[index].isNew">
      {{ player.team_nr + '/' + player.pos }} {{ player.fname + ' ' + player.lname }} 
    </b-form-checkbox>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
 <b-row>
      <b-col>
        
       <b-button size="sm" @click.stop="udpdateDb()">Save
       </b-button>
      </b-col>
    </b-row>
    </div>
</b-container>
  
</template>

<script>
import DBAPI from '../db/dbapi'
import CsvMatchParser from '../helper/CsvMatchParser'
import { s2i, makeCopy } from '../helper/Utils'
import MessageAlertMixin from '../mixins/MessageAlertMixin'

export default {
  name: 'EditPoll',
  mixins:[MessageAlertMixin],
  mounted() {
    this.processParams()
    this.processQuery()
    this.getData()

  },
  watch: {
    	'$route': function(newRoute, oldRoute) {
        this.hideAlert()
        this.processParams()
        this.processQuery()
      },
  },
  data () {
    return {
      appState:'loading',
      errorMessage:null,
      insertDbDisabled: false,
      teams: {},
      matchtes: [],
      playerSchedules: [],
      allplayers: [],
      poll: {},
      form: {
        title: '',
        group_rr: null,
        clubNr: null,
        teamNr: null,
        active: false,
      },
      playerEditModel:[],
      teamNrs: [1,2,3,4,5,6,7,8,9,10,11,12,13],
    }
  },
  computed: {
    
  },
  methods: {
    processParams() {
      
      if (this.$route.params) {
          this.pollId = this.$route.params.pollId
          this.$$$storeSet("lastPollId", this.pollId)
      } else {
        // poll zum Anzeigen nicht gefunden
        this.$router.push('/polls')
      }
    },

    processQuery() {
      // console.log("$route.query", JSON.stringify(this.$route.query))
      if (this.$route.query) {
      }
    
    },
    getData: function() {
      this.appState = 'loading'
      var self = this
      
      var userData = this.$$$userData()
      let userId = userData.user_id
      if (!userId) {
        console.error("nicht angemeldet!")
        self.appState = 'error'
        return
      }

      DBAPI.getPlayerSchedules({pollId:self.pollId}).then(function(response) {
        // console.log("response.data", response.data.playerSchedules);
        if (response.data.error){
          console.error("error in getPlayerSchedules", response.data.message)
          self.appState = 'error'

          //app.errorMessage = response.data.message;
        }	else {
          // console.log(response.data)
          var teams   = {}

          response.data.teams.forEach(t => {
              teams[t.club_nr] = t;
            })
          self.teams = teams
          
          self.matches = response.data.poll.matches
          self.playerSchedules = response.data.playerSchedules
          self.allplayers = response.data.allplayers

          self.poll = response.data.poll
          self.setFormFromPoll.call(self)
          self.initPlayerEditModel.call(self)
          self.appState = 'ready'
        }       
    })
      
    },
    goBack: function() {
      this.$router.go(-1);
    },
    setFormFromPoll() {
      this.form.title = this.poll.title
      this.form.groupId = this.poll.groupId
      this.form.clubNr = this.poll.club_nr
      this.form.teamNr = this.poll.team_nr
      this.form.active = this.poll.active
    },
    initPlayerEditModel() {
      var self = this
      this.playerEditModel = this.allplayers.map(ap => {
          let playerIsInPoll = self.playerSchedules.find(p => p._id === ap._id) ? true : false
          return {isOld: playerIsInPoll, isNew: playerIsInPoll, player_id:ap._id, poll_id:self.poll._id} 
      })
    },
    udpdateDb() {

      var updatedPoll = makeCopy(this.poll)
      
      updatedPoll.title   = this.form.title
      updatedPoll.groupId = this.form.groupId
      updatedPoll.club_nr = this.form.clubNr
      updatedPoll.team_nr = this.form.teamNr
      updatedPoll.active  = this.form.active
      
      var allplayersIds = this.allplayers.map(p=>p._id)
      updatedPoll.playerIds = allplayersIds.filter((p, index) => this.playerEditModel[index].isNew)

      let items = [updatedPoll]

      let del_items = this.playerEditModel.filter((pem) => {
        return pem.isOld && !pem.isNew
      })
      
      let upsert  = {action:'upsert', model:'Poll', keyNames:['_id'], items:items}
      let del     = {action:'delete', model:'PlayerMatchState', keyNames:['poll_id', 'player_id'], items:del_items}

      var self = this
      DBAPI.putUpsert([upsert, del]).then(function(response) {
          // console.log('response', response)
          if (response.data.error || response.data.errors ){
            
            console.error("error DBAPI.putUpsert:")
            console.error(response.data)
            self.showAlert(response.data)

          }	else {
            // console.log("upsert ok", response.data)
          }

      })
      .catch(e => {console.log("putUpsert catch: ", e)})
      .finally(() => self.insertDbClubsDisabled = false)
    },
   
    
  }
}

</script>

<style>

</style>
