
var db2csvMapping_match = [
    {to:'clicktt_match_nr',from:'BegegnungNr'},
    {to:'date',from:'Termin'}, // zusätzlich mit termin2date(Termin)
    {to:'home_club_nr',from:'HeimVereinNr'},
    {to:'home_team_nr',from:'HeimMannschaftNr'},
    {to:'away_club_nr',from:'GastVereinNr'},
    {to:'away_team_nr',from:'GastMannschaftNr'},
    // {to:'round',from:'Runde'},
    
]



class CsvMatchParser {
    constructor(input) {
        this.delimiter = ";"
        this.nl = "\n"
        this.input = input;
    }
    makeMatchArrayForDb(
                    clubNr,
                    teamNr,
                    VR_or_RR /* Vorrunde or Rueckrunde */) {
        if (!VR_or_RR || (VR_or_RR != 'vr' && VR_or_RR != 'rr')) {
            throw "VR_or_RR must be 'vr' or 'rr'"
        }
        if (!teamNr) {
            throw "teamNr must be set"
        }
        teamNr = "" + teamNr                        
        
        const matchesInputObject = this.parse();
        // console.log(JSON.stringify(matchesInputObject, undefined, 2));
        if (!clubNr) {
            clubNr = CsvMatchParser.tryFindClubId(matchesInputObject, teamNr)
            if (!clubNr) throw "clubId must be set"
        }
        clubNr = "" + clubNr                        
        
        
        
        const matches = []
        const club_nrs = { } // to check if club already in array clubs
        const clubs = [] 
        var groupId = 0
        for (var i = 0;i < matchesInputObject.lines.length; i++) { // iterate lines
            var inputMatch = matchesInputObject.lines[i]
            
            if (inputMatch["Runde"].toLowerCase() != VR_or_RR) {
                continue
            }

            
            if ((inputMatch["HeimVereinNr"] === clubNr && inputMatch["HeimMannschaftNr"] === teamNr)
            || (inputMatch["GastVereinNr"] === clubNr && inputMatch["GastMannschaftNr"] === teamNr)) {
            } else {
                continue
            }
            const s_id = CsvMatchParser.computeSeasonsId(inputMatch['Termin'])
            
            var dbMatch = {}
            dbMatch.state = 1
            
            // var dbHomeTeam = {}
            // var dbAwayTeam = {}
            dbMatch['season_nr'] = "" + s_id
            for (var j = 0;j<db2csvMapping_match.length;j++) {  // iterate fields
                const from = db2csvMapping_match[j].from
                const to   = db2csvMapping_match[j].to
                dbMatch[to] = "" + inputMatch[from]

                const home_club_nr = inputMatch['HeimVereinNr']
                const away_club_nr = inputMatch['GastVereinNr']
                
                if (!club_nrs[home_club_nr]) { // noch nicht da gewesen
                    club_nrs[home_club_nr] = 1 // merken, dass da war
                    clubs.push( { club_nr:home_club_nr,
                                  full_name: inputMatch['HeimVereinName'],
                                  short_name: inputMatch['HeimVereinName']
                                })
                }
                if (!club_nrs[away_club_nr]) { // noch nicht da gewesen
                    club_nrs[away_club_nr] = 1
                    clubs.push(   { club_nr:away_club_nr,
                                    full_name: inputMatch['GastVereinName'],
                                    short_name: inputMatch['GastVereinName']
                                    })
                }
            }
            dbMatch['date'] = CsvMatchParser.termin2date(dbMatch['date']);
            // dbMatch['round'] = inputMatch['Runde'].toLowerCase();
            matches.push(dbMatch)


        }
        // convert teams object to array

        return {clubId:clubNr, teamNr:teamNr, groupId:groupId, VR_or_RR:VR_or_RR, seasonYear:matchesInputObject.lines[0]['Saison'],
                        matches:matches, clubs:clubs}
    }
    parse() {
        
        if (!this.input) {
            throw "input not set"
        }
        const retVal = {fields:[], lines: []} // lines contains objects with attributes=fields
        const delimiter = this.delimiter
        const nl = this.nl
        var lineCnt = 0 // for error msg
        var lines = this.input.split(nl)
        // console.log(lines[0])
        if (!lines.length) {
            throw "no data in input or wrong delimiter, lineNr=" + lineCnt
        }

        const header = lines[0].split(delimiter).map(s => s.trim())
        if (!header.length) {
            throw "could not split header, lineNr=" + lineCnt
        }
        retVal.fields = header
        const fieldCnt = header.length
        // var headerMap = header.reduce(function ( total, current, i ) {
        //     total[ current ] = i;
        //     return total;
        // }, {});
        // console.log(headerMap)
        for (var i = 1;i < lines.length; i++) {
            lineCnt = i

            const lineArr = lines[i].split(delimiter).map(s => s.trim())
            if (!lineArr.length) {
                throw "could not split line, lineNr=" + lineCnt
            }
            if (lineArr.length == 1) { // handle new line at eof
                break;
            }
            if (lineArr.length != fieldCnt) {
                throw "field count in line != field count in header, lineNr=" + lineCnt
            }

            // the following reduce makes an object with attributes from header
            // and corresponding values from the current line
            const lineObj = lineArr.reduce(function ( 
                accumulator,
                currentValue,
                currentIndex,
                array) {
                accumulator[header[currentIndex]] = currentValue
                return accumulator
              }, { } /* initial accumulator */ )
            retVal.lines.push(lineObj)
        }
        return retVal
    }
    static termin2date(termin) {
        const day = termin.substring(0,2)
        const month = termin.substring(3,5)
        const year = termin.substring(6,10)
        const hour = termin.substring(11,13)
        const minutes = termin.substring(14,16)

        return year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':00' 
    }
    static computeSeasonsId(termin) {
        // seasons_id = 1 is first half in 2017
        const year = Number(termin.substring(6,10))
        const month = Number(termin.substring(3,5))
        var seasons_id = (year-2017) * 2 + 1
        if (month < 7) { // Hinserie
            seasons_id -= 1
        }
        return seasons_id
    }

    static tryFindClubId(matchesInputObject, teamNr) {
        if (!teamNr) {
            return null
        }
        var teamIdCounters = {}
        for (var i = 0; i<matchesInputObject.lines.length;i++) {
            var inputMatch = matchesInputObject.lines[i]
            
            if (inputMatch["HeimMannschaftNr"] === teamNr) {
                teamIdCounters[inputMatch["HeimVereinNr"]] = !teamIdCounters[inputMatch["HeimVereinNr"]] ? 1 : (teamIdCounters[inputMatch["HeimVereinNr"]] + 1)
            }
            if (inputMatch["GastMannschaftNr"] === teamNr) {
                teamIdCounters[inputMatch["GastVereinNr"]] = !teamIdCounters[inputMatch["GastVereinNr"]] ? 1 : (teamIdCounters[inputMatch["GastVereinNr"]] + 1)
            }
          
        }
        
        var maxMatchesTeamId = -1
        var maxTeamId = ""
        for (var key in teamIdCounters) {
            if (teamIdCounters[key] > maxMatchesTeamId) {
                maxMatchesTeamId = teamIdCounters[key]
                maxTeamId = key
            }
        }

        // console.log(maxTeamId, maxMatchesTeamId)
        if (maxMatchesTeamId <= 3) {
            return null
        }
        return maxTeamId
    }
    

    static getTestData() {
            var testData = 
        `Termin;Wochentag;Verband;Saison;Meisterschaft;Altersklasse;Liga;Staffel;Runde;BegegnungNr;HalleNr;HalleName;HalleStrasse;HallePLZ;HalleOrt;HeimVereinVerband;HeimVereinNr;HeimVereinName;HeimMannschaftAltersklasse;HeimMannschaftNr;GastVereinVerband;GastVereinNr;GastVereinName;GastMannschaftAltersklasse;GastMannschaftNr;SpieleHeim;SpieleGast
        06.09.2017 20:00;Mi.;WTTV;2017/18;Kreis Münster/Warendorf 2017/18;Herren;Herren - 1. Kreisklasse;Herren - 1. Kreisklasse Gr. 1;VR;69;1;Überwasserschule;Katthagen 7 (Zufahrt vom Schlossplatz über Überwasserstr. und Jüdefelder Str., Parkmöglichkeit auf dem Schlossplatz);48143;Münster;WTTV;172018;TG Münster;Herren;3;WTTV;172024;1. Ping Pong Club Münster;Herren;1;3;9
        14.09.2017 19:45;Do.;WTTV;2017/18;Kreis Münster/Warendorf 2017/18;Herren;Herren - 1. Kreisklasse;Herren - 1. Kreisklasse Gr. 1;VR;76;1;Hauptschule Coerde;Dachsleite 32;48157;Münster;WTTV;172024;1. Ping Pong Club Münster;Herren;1;WTTV;172032;DJK BW Greven;Herren;3;9;4
        22.09.2017 20:00;Fr.;WTTV;2017/18;Kreis Münster/Warendorf 2017/18;Herren;Herren - 1. Kreisklasse;Herren - 1. Kreisklasse Gr. 1;VR;84;1;Hansaschule;Hansaring 80 - Eingang Schillerstr.;48155;Münster;WTTV;172005;1. TTC Münster;Herren;7;WTTV;172024;1. Ping Pong Club Münster;Herren;1;8;8
        27.09.2017 20:00;Mi.;WTTV;2017/18;Kreis Münster/Warendorf 2017/18;Herren;Herren - 1. Kreisklasse;Herren - 1. Kreisklasse Gr. 1;VR;85;1;Ida-Grundschule;Vörnste Esch 19;48167;Münster-Gremmendorf;WTTV;172048;SC Gremmendorf;Herren;1;WTTV;172024;1. Ping Pong Club Münster;Herren;1;7;9
        05.10.2017 19:45;Do.;WTTV;2017/18;Kreis Münster/Warendorf 2017/18;Herren;Herren - 1. Kreisklasse;Herren - 1. Kreisklasse Gr. 1;VR;91;1;Hauptschule Coerde;Dachsleite 32;48157;Münster;WTTV;172024;1. Ping Pong Club Münster;Herren;1;WTTV;172014;SC Westfalia Kinderhaus;Herren;4;9;0
        12.10.2017 19:45;Do.;WTTV;2017/18;Kreis Münster/Warendorf 2017/18;Herren;Herren - 1. Kreisklasse;Herren - 1. Kreisklasse Gr. 1;VR;99;1;Alte Turnhalle Hiltrup-Mitte;Kardinalstraße 27;48165;Münster-Hiltrup;WTTV;172002;TuS Hiltrup;Herren;5;WTTV;172024;1. Ping Pong Club Münster;Herren;1;8;8
        19.10.2017 19:45;Do.;WTTV;2017/18;Kreis Münster/Warendorf 2017/18;Herren;Herren - 1. Kreisklasse;Herren - 1. Kreisklasse Gr. 1;VR;105;1;Hauptschule Coerde;Dachsleite 32;48157;Münster;WTTV;172024;1. Ping Pong Club Münster;Herren;1;WTTV;172001;DJK Borussia Münster;Herren;4;8;8
        09.11.2017 20:00;Do.;WTTV;2017/18;Kreis Münster/Warendorf 2017/18;Herren;Herren - 1. Kreisklasse;Herren - 1. Kreisklasse Gr. 1;VR;112;1;Peter-Demling-Ballsspielhalle;Gievenbecker Weg 200;48149;Münster;WTTV;172041;1. FC Gievenbeck;Herren;3;WTTV;172024;1. Ping Pong Club Münster;Herren;1;9;3
        16.11.2017 19:45;Do.;WTTV;2017/18;Kreis Münster/Warendorf 2017/18;Herren;Herren - 1. Kreisklasse;Herren - 1. Kreisklasse Gr. 1;VR;116;1;Hauptschule Coerde;Dachsleite 32;48157;Münster;WTTV;172024;1. Ping Pong Club Münster;Herren;1;WTTV;172019;SV BW Aasee;Herren;1;9;1
        20.11.2017 19:45;Mo.;WTTV;2017/18;Kreis Münster/Warendorf 2017/18;Herren;Herren - 1. Kreisklasse;Herren - 1. Kreisklasse Gr. 1;VR;120;1;Annetteschule;Höftestraße 4;48167;Münster;WTTV;172021;TSV Angelmodde;Herren;1;WTTV;172024;1. Ping Pong Club Münster;Herren;1;1;9
        30.11.2017 19:45;Do.;WTTV;2017/18;Kreis Münster/Warendorf 2017/18;Herren;Herren - 1. Kreisklasse;Herren - 1. Kreisklasse Gr. 1;VR;127;1;Hauptschule Coerde;Dachsleite 32;48157;Münster;WTTV;172024;1. Ping Pong Club Münster;Herren;1;WTTV;172053;SV Bösensell;Herren;1;9;1
        18.01.2018 19:45;Do.;WTTV;2017/18;Kreis Münster/Warendorf 2017/18;Herren;Herren - 1. Kreisklasse;Herren - 1. Kreisklasse Gr. 1;RR;899;1;Hauptschule Coerde;Dachsleite 32;48157;Münster;WTTV;172024;1. Ping Pong Club Münster;Herren;1;WTTV;172018;TG Münster;Herren;3;9;5
        29.01.2018 20:00;Mo.;WTTV;2017/18;Kreis Münster/Warendorf 2017/18;Herren;Herren - 1. Kreisklasse;Herren - 1. Kreisklasse Gr. 1;RR;905;3;Emssporthalle;Lindenstr. 53;48268;Greven;WTTV;172032;DJK BW Greven;Herren;3;WTTV;172024;1. Ping Pong Club Münster;Herren;1;1;9
        01.02.2018 19:45;Do.;WTTV;2017/18;Kreis Münster/Warendorf 2017/18;Herren;Herren - 1. Kreisklasse;Herren - 1. Kreisklasse Gr. 1;RR;911;1;Hauptschule Coerde;Dachsleite 32;48157;Münster;WTTV;172024;1. Ping Pong Club Münster;Herren;1;WTTV;172005;1. TTC Münster;Herren;7;9;1
        15.02.2018 19:45;Do.;WTTV;2017/18;Kreis Münster/Warendorf 2017/18;Herren;Herren - 1. Kreisklasse;Herren - 1. Kreisklasse Gr. 1;RR;917;1;Hauptschule Coerde;Dachsleite 32;48157;Münster;WTTV;172024;1. Ping Pong Club Münster;Herren;1;WTTV;172048;SC Gremmendorf;Herren;1;9;2
        23.02.2018 19:30;Fr.;WTTV;2017/18;Kreis Münster/Warendorf 2017/18;Herren;Herren - 1. Kreisklasse;Herren - 1. Kreisklasse Gr. 1;RR;924;1;Grundschule West;Josef-Beckmann-Str. 33;48159;Münster;WTTV;172014;SC Westfalia Kinderhaus;Herren;4;WTTV;172024;1. Ping Pong Club Münster;Herren;1;5;9
        01.03.2018 19:45;Do.;WTTV;2017/18;Kreis Münster/Warendorf 2017/18;Herren;Herren - 1. Kreisklasse;Herren - 1. Kreisklasse Gr. 1;RR;928;1;Hauptschule Coerde;Dachsleite 32;48157;Münster;WTTV;172024;1. Ping Pong Club Münster;Herren;1;WTTV;172002;TuS Hiltrup;Herren;5;9;1
        06.03.2018 20:15;Di.;WTTV;2017/18;Kreis Münster/Warendorf 2017/18;Herren;Herren - 1. Kreisklasse;Herren - 1. Kreisklasse Gr. 1;RR;933;1;Ludwig-Erhard-Schule;Gut Insel 41;48151;Münster;WTTV;172001;DJK Borussia Münster;Herren;4;WTTV;172024;1. Ping Pong Club Münster;Herren;1;6;9
        12.03.2018 20:00;Mo.;WTTV;2017/18;Kreis Münster/Warendorf 2017/18;Herren;Herren - 1. Kreisklasse;Herren - 1. Kreisklasse Gr. 1;RR;949;1;Matthias-Claudius-Grundschule;Gut Insel 36;48151;Münster;WTTV;172019;SV BW Aasee;Herren;1;WTTV;172024;1. Ping Pong Club Münster;Herren;1;4;9
        15.03.2018 19:45;Do.;WTTV;2017/18;Kreis Münster/Warendorf 2017/18;Herren;Herren - 1. Kreisklasse;Herren - 1. Kreisklasse Gr. 1;RR;940;1;Hauptschule Coerde;Dachsleite 32;48157;Münster;WTTV;172024;1. Ping Pong Club Münster;Herren;1;WTTV;172041;1. FC Gievenbeck;Herren;3;9;4
        12.04.2018 19:45;Do.;WTTV;2017/18;Kreis Münster/Warendorf 2017/18;Herren;Herren - 1. Kreisklasse;Herren - 1. Kreisklasse Gr. 1;RR;951;1;Hauptschule Coerde;Dachsleite 32;48157;Münster;WTTV;172024;1. Ping Pong Club Münster;Herren;1;WTTV;172021;TSV Angelmodde;Herren;1;9;2
        16.04.2018 20:00;Mo.;WTTV;2017/18;Kreis Münster/Warendorf 2017/18;Herren;Herren - 1. Kreisklasse;Herren - 1. Kreisklasse Gr. 1;RR;957;1;Grundschule Bösensell;Bahnhofstr. 7;48308;Senden;WTTV;172053;SV Bösensell;Herren;1;WTTV;172024;1. Ping Pong Club Münster;Herren;1;0;0
        `

        return testData
    }
    
}

export default CsvMatchParser;

    // var fs = require('fs');

    // var testInput = fs.readFileSync('/Users/lothar/Downloads/Vereinsspielplan_20180414231430.csv','latin1');
    // var testInput = CsvMatchParser.getTestData()
    // var p = new CsvMatchParser(testInput)
    // const result = p.makeMatchArrayForDb("RR")
    // // console.log(result)
    // console.log(result)